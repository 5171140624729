<template>
  <div>
    <div class="top" style="margin: 20px 0">
      <el-date-picker
        style="margin-right: 20px"
        v-model="time"
        @change="timeUpdate"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd"
      >
      </el-date-picker>
      <el-button type="primary" style="margin-right: 20px" @click="recharge"
        >充 值</el-button
      >
      <span style="display: inline-block; font-size: 16px"
        >剩余余额:
        {{
          surplusMoney % 1 === 0
            ? surplusMoney
            : surplusMoney.toFixed(1)
        }}</span
      >
    </div>
    <el-dialog
      :show-close="false"
      title="自助充值"
      :visible.sync="dialogTableVisible"
      width="478px"
      center
    >
      <el-form
        :model="dataList"
        ref="dataList"
        label-width="100px"
        class="demo-ruleForm"
      >
        <div class="money-wrap">
          <!-- <div
            @click="selectedMoney('3000')"
            :class="money === '3000' ? 'light' : ''"
          >
            3000
          </div> -->
          <div
            @click="selectedMoney('5000')"
            :class="money === '5000' ? 'light' : ''"
          >
            5000
          </div>
          <div
            @click="selectedMoney('10000')"
            :class="money === '10000' ? 'light' : ''"
          >
            10000
          </div>
          <div
            @click="selectedMoney('20000')"
            :class="money === '20000' ? 'light' : ''"
          >
            20000
          </div>
          <div
            @click="selectedMoney('30000')"
            :class="money === '30000' ? 'light' : ''"
          >
            30000
          </div>
          <div
            @click="selectedMoney('50000')"
            :class="money === '50000' ? 'light' : ''"
          >
            50000
          </div>
          <el-input
            @focus="changeMoney"
            v-model="writeMoney"
            placeholder="自定义充值金额 (元)"
            style="width: 204px; height: 46px"
            clearable
          >
          </el-input>
        </div>
        <div class="bankCard">
          <div class="bankLeft">
<!--            <div
            class="wrap"
              @click="selectedRadio('5')">
              <img src="https://sitelu.oss-cn-hangzhou.aliyuncs.com/assets/kuailehudong_orgrization/image/chat.png" alt="">
              <div class="divWarp">
                <div :class="radio == '5' ? 'lightOutSide' : 'outSide'">
                  <div class="inside">
                  </div>
                </div>
              </div>
              <span :class="radio == '5' ? 'lightColor' : ''">微信充值</span>
            </div>-->
            <div
            class="wrap"
              @click="selectedRadio('3')">
              <img src="https://sitelu.oss-cn-hangzhou.aliyuncs.com/assets/kuailehudong_orgrization/image/pay.png" alt="">
              <div class="divWarp">
                <div :class="radio == '3' ? 'lightOutSide' : 'outSide'">
                  <div class="inside">
                  </div>
                </div>
              </div>
              <span :class="radio == '3' ? 'lightColor' : ''">支付宝充值</span>
            </div>
            <div
            class="wrap"
              @click="selectedRadio('4')">
              <img src="https://sitelu.oss-cn-hangzhou.aliyuncs.com/assets/kuailehudong_orgrization/image/bank.png" alt="">
              <div class="divWarp">
                <div :class="radio == '4' ? 'lightOutSide' : 'outSide'">
                  <div class="inside">
                  </div>
                </div>
              </div>
              <span :class="radio == '4' ? 'lightColor' : ''">银行卡充值</span>
            </div>
            <!-- <el-radio-group v-model="radio">
              <el-radio label="5">微信充值</el-radio>
              <el-radio label="3">支付宝充值</el-radio>
              <el-radio label="4">银行卡充值</el-radio>
            </el-radio-group> -->
          </div>
          <div
            style="width: 150px"
            v-show="radio === '4'"
            :class="!bankList.length ? 'hiddenCard' : ''"
          >
            <el-radio-group v-model="idcard">
              <div v-for="item in bankList" :key="item.userBankId">
                <el-radio :label="item.userBankId">{{
                  item.bankName
                }}</el-radio>
              </div>
            </el-radio-group>
          </div>
          <div v-show="radio === '4'">
            <el-radio-group v-model="newCard">
              <div><el-radio :label="1">添加新卡</el-radio></div>
            </el-radio-group>
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogTableVisible = false">取 消</el-button>
        <el-button type="primary" @click="preCard" v-preventClick
          >确 定</el-button
        >
      </span>
      <el-dialog
        :show-close="false"
        title="微信充值"
        :visible.sync="showCode"
        width="478px"
        center
        append-to-body
      >
        <el-form ref="bankList" class="demo-ruleForm">
          <div class="codeWrap">
            <img :src="codeUrl" alt="" />
            <div class="weChat-tip">请扫码支付</div>
          </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showCode = false">取 消</el-button>
        </span>
      </el-dialog>
      <el-dialog
        :show-close="false"
        title="银行卡充值"
        :visible.sync="addBank"
        width="478px"
        center
        append-to-body
      >
        <el-form ref="bankList" label-width="120px" class="demo-ruleForm">
          <el-form-item
            prop="channelName"
            label="持卡人姓名"
            width="100%"
            style="padding-top: 10px"
            ><el-input
              v-model="assBankList.username"
              style="width: 260px"
              placeholder="请输入持卡人姓名"
            ></el-input>
          </el-form-item>
          <el-form-item prop="channelName" label="持卡人身份证" width="100%"
            ><el-input
              v-model="assBankList.idCard"
              style="width: 260px"
              placeholder="请输入持卡人身份证号码"
              type="number"
              @input="assBankList.idCard = assBankList.idCard.slice(0, 18)"
            ></el-input>
          </el-form-item>
          <el-form-item prop="channelName" label="银行卡号" width="100%"
            ><el-input
              v-model="assBankList.bankNo"
              style="width: 260px"
              placeholder="请输入银行卡号"
              type="number"
              @input="assBankList.bankNo = assBankList.bankNo.slice(0, 19)"
            ></el-input>
          </el-form-item>
          <el-form-item
            prop="channelName"
            label="预留手机号"
            width="100%"
            style="padding-bottom: 20px"
            ><el-input
              v-model="assBankList.phone"
              style="width: 260px"
              placeholder="请输入银行预留手机号"
              type="number"
              @input="assBankList.phone = assBankList.phone.slice(0, 11)"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addBank = false">取 消</el-button>
          <el-button type="primary" @click="addBankLiist" v-preventClick
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <el-dialog
        :show-close="false"
        title="银行卡充值"
        :visible.sync="showAgreement"
        width="478px"
        center
        append-to-body
      >
        <el-form ref="bankList" label-width="120px" class="demo-ruleForm">
          <div class="agreementWrap">
            <div>
              请阅读并同意
              <span @click="openUrl">《 {{ agreementName }}》</span>
            </div>
            <el-button
              type="primary"
              style="margin: 20px 0"
              @click="agineCodeTo"
              >我已阅读并同意</el-button
            >
            <div @click="showAgreement = false">不同意</div>
          </div>
        </el-form>
      </el-dialog>
      <el-dialog
        :show-close="false"
        title="银行卡充值"
        :visible.sync="bankCode"
        width="478px"
        center
        append-to-body
      >
        <el-form ref="bankList" label-width="120px" class="demo-ruleForm">
          <div class="bank-code">
            <div>已向您手机号{{ hiddenPhone }}发送验证码</div>
            <div class="pcCode">
              <div class="code-tip">验证码:</div>
              <div class="codeInside">
                <el-input
                  placeholder="请输入验证码"
                  v-model="pwd"
                  type="number"
                  @input="pwd = pwd.slice(0, 6)"
                >
                </el-input>
                <button :disabled="disabled" class="code" @click="agineCodeTo" type="button">
                  {{ yzm }}
                </button>
              </div>
            </div>
          </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="bankCode = false">取 消</el-button>
          <el-button type="primary" @click="confirm" v-preventClick
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </el-dialog>
    <el-table :data="tableData" style="width: 100%;" :stripe="true">
      <el-table-column prop="subPlan" label="子计划名"> </el-table-column>
      <el-table-column prop="applyNum" label="申请数"> </el-table-column>
      <el-table-column prop="consumeMoney" label="消耗">
        <template slot-scope="scope">
          <div>
            {{
              scope.row.consumeMoney % 1 === 0
                ? scope.row.consumeMoney
                : scope.row.consumeMoney.toFixed(1)
            }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      :total="total"
      :page.sync="queryList.pageIdx"
      :limit.sync="queryList.pageSize"
      @pagination="pagination"
    />
  </div>
</template>

<script>
function jialing(num) {
  if (num >= 10) {
    return num;
  } else {
    return "0" + num;
  }
}
var day1 = new Date();
day1.setTime(day1.getTime());
var s1 =
  day1.getFullYear() +
  "-" +
  jialing(day1.getMonth() + 1) +
  "-" +
  jialing(day1.getDate());
var day3 = new Date();
day3.setTime(day3.getTime() + 24 * 60 * 60 * 1000);
var s3 =
  day3.getFullYear() +
  "-" +
  jialing(day3.getMonth() + 1) +
  "-" +
  jialing(day3.getDate());

import {
  sub_plan_list,
  pre_bind_card,
  get_bind_bank,
  get_agreement,
  confirm_bind_pay,
  add_bank,
  recharge_initiatePay,
  find_order_state,
} from "../../api/SubplanData";
import { getBalanceInfo } from "../../api/header";
import Pagination from "@/components/Pagination";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      agreementUrl: "",
      showAgreement: false,
      agreementName: "",
      addBank: false,
      assBankList: {
        username: "",
        idCard: "",
        phone: "",
        bankNo: "",
      },
      bankList: [],
      idcard: "",
      radio: "3",
      money: "5000",
      showlight: false,
      writeMoney: "",
      surplusMoney: "",
      time: [s1, s3],
      queryList: {
        pageIdx: 1,
        pageSize: 10,
      },
      options: {},
      tableData: [],
      total: 0,
      title: "新增员工",
      dialogTableVisible: false,
      dataList: {
        loginUser: "",
        status: 1,
        userName: "",
      },
      dataListCopy: {},
      bankCode: false,
      hiddenPhone: "",
      codePhone: "",
      pwd: "",
      yzm: "",
      disabled: true,
      times: 60,
      type: "",
      rechargeMoney: "",
      newCard: "",
      userBankId: "",
      showCode: false,
      codeUrl: "",
      orderNo: "",
      timerCode: null,
      formAciton: ""
    };
  },
  watch: {
    showAgreement(newval) {
      if (!newval) {
        this.assBankList.username = "";
        this.assBankList.idCard = "";
        this.assBankList.bankNo = "";
        this.assBankList.phone = "";
      }
    },
    dialogTableVisible(newval) {
      if (!newval) {
        this.idcard = "";
        this.writeMoney = "";
        this.money = "5000";
        this.newCard = "";
        this.radio = '5'
      }
    },
    newCard(newval) {
      if (newval) {
        this.addBank = true;
      }
    },
    bankCode(newval) {
      if (!newval) {
        this.pwd = "";
        this.times = 60
        this.hiddenPhone = ""
        this.idcard = ''
      }
    },
    addBank(newval) {
      if (!newval) {
        this.assBankList.username = "";
        this.assBankList.idCard = "";
        this.assBankList.bankNo = "";
        this.assBankList.phone = "";
        this.idcard = "";
        this.newCard = "";
        this.writeMoney = "";
        this.money = "5000";
      }
    },
    showCode(newval) {
      if (!newval) {
        this.writeMoney = "";
        this.money = "5000";
        clearInterval(this.timerCode);
      } else {
        let dto = {};
        dto.orderNo = this.orderNo;
        this.timerCode = setInterval(() => {
          find_order_state(dto).then((res) => {
            console.log(res);
            if (res.code === 200) {
              if (res.data === 2) {
                this.showCode = false;
                this.dialogTableVisible = false;
                this.$message({
                  message: "充值成功",
                  type: "success",
                });
                clearInterval(this.timerCode);
                getBalanceInfo().then(
                  (res) => {
                    if (res.code == 200) {
                      this.surplusMoney = res.data.surplusMoney;
                    }
                  }
                );
              }
            }
          });
        }, 1000);
      }
    },
    idcard(newval) {
      if (newval === 7) {
        this.addBank = true;
      }
    },
  },
  mounted() {
    getBalanceInfo().then((res) => {
      if (res.code == 200) {
        this.surplusMoney = res.data.surplusMoney;
      }
    });
    this.queryList.startTime = s1;
    this.queryList.endTime = s3;
    this.loanList();
    this.dataListCopy = JSON.stringify(this.dataList);
  },
  methods: {
    selectedRadio(val) {
      this.radio = val
    },
    selectedMoney(data) {
      this.money = data;
      this.writeMoney = "";
    },
    postCode() {
      pre_bind_card({
        userBankId: this.idcard,
      }).then((res) => {
        if (res.code === 200) {
          this.times = 60;
          this.timer();
        }
      });
    },
    //验证码
    timer() {
      if (this.times > 0) {
        this.disabled = true;

        this.times--;

        this.yzm = this.times + "s重新获取";

        let timer = setTimeout(this.timer, 1000);
        if(!this.bankCode) {
          clearTimeout(timer)
        }
      } else {
        this.times = 0;

        this.yzm = "重新获取";

        this.disabled = false;
      }
    },
    preCard() {
      if (!this.money && !this.writeMoney) {
        this.$message.error("请先选择充值金额");
        return false;
      }
      if (!this.idcard && this.radio === "4") {
        this.$message.error("请先选择银行卡");
        return false;
      }
      this.rechargeMoney = this.money ? this.money : this.writeMoney;
      if(this.rechargeMoney > 6000 && this.radio === "5") {
        this.$message.error("微信支付单笔限额￥6000，单日限额￥9000");
        return false;
      }
      // if(Number(this.rechargeMoney) < 3000) {
      //   this.$message.error("充值金额最低额度3000")
      //   return false
      // }
      if (this.radio == "4") {
        if (this.bankList) {
          this.bankList.forEach((item) => {
            if (item.userBankId === this.idcard) {
              this.hiddenPhone =
                item.phone.slice(0, 3) + "****" + item.phone.slice(7, 11);
              this.loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
              });
              this.rechargeMoney = this.money ? this.money : this.writeMoney;
              recharge_initiatePay({
                paySource: this.radio,
                rechargeMoney: this.rechargeMoney,
                userBankId: item.userBankId,
              })
                .then((res) => {
                  if (res.code === 200) {
                    this.bankCode = true;
                    this.type = 1;
                    this.times = 60;
                    this.timer();
                    this.$message({
                      message: "获取验证码成功",
                      type: "success",
                    });
                    this.loading.close();
                  } else {
                    this.loading.close();
                  }
                })
                .catch(() => {
                  this.loading.close();
                  this.$message.error("请稍后重试");
                  return false;
                });
            }
          });
        } else {
          this.$message.error("请先绑定银行卡");
          return false;
        }
      } else if (this.radio == "5" || this.radio == "3") {
        recharge_initiatePay({
          paySource: this.radio,
          rechargeMoney: this.rechargeMoney,
          type: 0,
        }).then((res) => {
          if (this.radio == "5") {
            this.codeUrl = JSON.parse(res.data).qrCode;
            this.showCode = true;
            this.orderNo = JSON.parse(res.data).orderNo;
          } else {
            this.formAciton = JSON.parse(res.data).qrCode;
            this.orderNo = JSON.parse(res.data).orderNo;
            document.write(this.formAciton)
          }
        });
      }
    },
    confirm() {
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      confirm_bind_pay({
        code: this.pwd,
        type: this.type,
      })
        .then((res) => {
          if (res.code === 200) {
            this.bankCode = false;
            this.showAgreement = false;
            this.addBank = false;
            if (!this.type) {
              this.$message({
                message: "绑卡成功",
                type: "success",
              });

              get_bind_bank()
                .then((res) => {
                  if (res.code === 200) {
                    if (!res.data) {
                      this.bankList = [];
                    } else {
                      this.bankList = res.data;
                    }
                  } else {
                    this.loading.close();
                  }
                })

                .catch(() => {
                  this.loading.close();
                  this.$message.error("请稍后重试");
                  return false;
                });
            } else {
              this.bankCode = false;
              this.dialogTableVisible = false;
              this.$message({
                message: "充值成功",
                type: "success",
              });
            }
            setTimeout(() => {
              this.loading.close();
            }, 1000);
          } else {
            this.loading.close();
          }
        })
        .catch(() => {
          this.loading.close();
          this.$message.error("请稍后重试");
          return false;
        });
    },
    agineCodeTo() {
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      if(this.radio == "4" && this.idcard) {
        recharge_initiatePay({
                paySource: this.radio,
                rechargeMoney: this.rechargeMoney,
                userBankId: this.idcard,
              })
                .then((res) => {
                  if (res.code === 200) {
                    this.bankCode = true;
                    this.type = 1;
                    this.times = 60;
                    this.timer();
                    this.$message({
                      message: "获取验证码成功",
                      type: "success",
                    });
                    this.loading.close();
                  } else {
                    this.loading.close();
                  }
                })
                .catch(() => {
                  this.loading.close();
                  this.$message.error("请稍后重试");
                  return false;
                });
      } else {
        this.hiddenPhone =
        this.assBankList.phone.slice(0, 3) + "****" + this.assBankList.phone.slice(7, 11);
      pre_bind_card({
        userBankId: this.userBankId,
      })
        .then((res) => {
          if (res.code === 200) {
            this.bankCode = true;
            this.times = 60;
            this.timer();
            this.$message({
              message: "获取验证码成功",
              type: "success",
            });
            this.loading.close();
          } else {
            this.loading.close();
          }
        })

        .catch(() => {
          this.loading.close();
          this.$message.error("请稍后重试");
          return false;
        });
      }
    },
    agineCo() {
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    },
    agineCode() {
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this;
      pre_bind_card({
        userBankId: this.idcard,
      })
        .then((res) => {
          if (res.code === 200) {
            this.bankCode = true;
            this.times = 60;
            this.timer();
            this.$message({
              message: "获取验证码成功",
              type: "success",
            });
            this.loading.close();
          } else {
            this.loading.close();
          }
        })

        .catch(() => {
          this.loading.close();
          this.$message.error("请稍后重试");
          return false;
        });
    },
    openUrl() {
      window.open(this.agreementUrl);
    },
    addBankLiist() {
      if (
        !this.assBankList.username
      ) {
        this.$message.error("请填写您的姓名");
        return false;
      }
      if (
        !this.assBankList.idCard
      ) {
        this.$message.error("请输入您的身份证号码");
        return false;
      }
      if (
        !this.assBankList.bankNo
      ) {
        this.$message.error("请输入您的银行卡号");
        return false;
      }
      if (
        !this.assBankList.phone
      ) {
        this.$message.error("请输入您的银行预留手机号");
        return false;
      }
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      add_bank(this.assBankList)
        .then((res) => {
          if (res.code === 200) {
            this.showAgreement = true;
            this.userBankId = res.data;
            get_agreement({
              bankNo: this.assBankList.bankNo,
            })
              .then((res) => {
                if (res.code === 200) {
                  this.agreementName = res.data[0].agreementName;
                  this.agreementUrl = res.data[0].agreementUrl;
                  this.type = 0;
                  this.loading.close();
                } else {
                  this.loading.close();
                }
              })
              .catch(() => {
                this.loading.close();
                this.$message.error("请稍后重试");
                return false;
              });
          } else {
            this.loading.close();
          }
        })
        .catch(() => {
          this.loading.close();
          this.$message.error("请稍后重试");
          return false;
        });
    },
    changeMoney() {
      this.money = "";
    },
    recharge() {
      get_bind_bank().then((res) => {
        if (res.code === 200) {
          if (!res.data) {
            this.bankList = [];
          } else {
            this.bankList = res.data;
          }
        }
        this.dialogTableVisible = true;
      });
    },
    loanList() {
      sub_plan_list(this.queryList).then((res) => {
        this.tableData = res.data.records;
        this.total = res.data.total;
        this.tableData.forEach((item) => {
          item.applyNum = item.applyNum;
          item.consumeMoney = item.consumeMoney;
        });
      });
    },
    timeUpdate(val) {
      if (val != null) {
        this.queryList.startTime = val[0];
        this.queryList.endTime = val[1];
      } else {
        this.queryList.startTime = "";
        this.queryList.endTime = "";
      }
      this.loanList();
    },
    //分页
    pagination(params) {
      if (params.page) {
        this.queryList.pageIdx = params.page;
      } else {
        this.queryList.pageIdx = 1;
        this.queryList.pageSize = params.limit;
      }
      this.loanList();
    },
  },
};
</script>

<style scoped>
::v-deep .el-date-editor .el-range-separator {
  width: 9%;
}
::v-deep .el-date-editor--daterange.el-input__inner {
  width: 250px !important;
}
::v-deep .el-select {
  width: 150px;
  margin-right: 10px;
}
::v-deep .el-radio {
  margin-right: 10px;
}

::v-deep thead th {
  color: #000;
  background: rgb(250, 250, 250);
}

::v-deep .cell {
  text-align: center;
}

::v-deep .el-pagination {
  text-align: center;
}

.money-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.money-wrap div {
  width: 92px;
  height: 46px;
  border-radius: 3px;
  background-color: rgb(164, 173, 179);
  margin-right: 20px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
}

.money-wrap div:nth-of-type(4n) {
  margin-right: 0 !important;
}

.money-wrap div:last-child {
  background-color: transparent !important;
  margin-right: 0;
}

/* .money-wrap input {
  outLine:none;
  border: 1px solid #c7c1c1;
  border-radius: 3px;
  width: 204px;
  height: 46px;
  padding: 0 10px;
} */
.light {
  background-color: #409eff !important;
}

.bankCard {
  display: flex;
}

.bankLeft {
  width: 150px;
  padding-top: 5px;
}

::v-deep .el-radio {
  display: block;
  margin-top: 8px;
}

.agreementWrap {
  text-align: center;
  padding: 95px 0 150px;
  color: #000;
}

.agreementWrap div:first-child {
  font-weight: 700;
}

.agreementWrap div span {
  display: inline-block;
  color: #409eff;
  font-weight: 500;
}

::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

::v-deep .bank-code .el-input__inner {
  height: 35px !important;
  border: 0 !important;
  font-size: 14px;
  width: 220px;
}

::v-deepinput[type="number"] {
  -moz-appearance: textfield !important;
}

.codeInside {
  position: relative;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
}

.code {
  background-color: transparent;
  color: #0088f4;
  position: absolute;
  right: 10px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 12px;
  border: 0;
  height: 35px;
}

.pcCode {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.el-input {
  width: 220px;
}

.code-tip {
  margin-right: 20px;
  color: #000;
}

.bank-code {
  text-align: center;
  margin: 75px 0;
}

.bank-code div:first-child {
  color: #000;
}

.codeWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.codeWrap img {
  width: 160px;
  height: 160px;
  margin: 50px 0 15px;
}

.weChat-tip {
  margin-bottom: 50px;
}

::v-deep .el-dialog--center .el-dialog__footer {
  text-align: right !important;
}

.hiddenCard {
  width: 0 !important;
}


.bankLeft img {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.bankLeft .wrap {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.divWarp {
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.outSide {
  border: 1px solid #c1b9b9;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inside {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #fff;
}

.lightOutSide {
  background-color: #0088f4 ;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lightColor {
  color: #409eff;
}
</style>